<template>
  <div
    :key="loading"
    class="userpic-block"
    :class="{'cursor-pointer': !loading }"
    @contextmenu.prevent.stop=""
  >
    <img
      v-if="userPic || preview"
      class="userpic-image"
      :src="preview ? preview : userPic"
      alt=""
      referrerpolicy="no-referrer"
      loading="lazy"
      @error="userPicError()"
    >

    <div v-else class="no-userpic-image icon-grey-second icon-account_circle" />

    <div
      v-if="loading"
      class="userpic-top-icon-block animation-pulse"
    >
      <div class="square-1_25 icon-grey icon-hourglass_empty"/>
    </div>

    <div
      v-else-if="showEdit"
      class="userpic-top-icon-block opacity_0 hover_opacity-0_75"
      @click.prevent.stop="$emit('clicked')"
    >
      <div class="square-1_25 icon-grey icon-edit" />
    </div>

    <div
      v-else-if="randomize"
      ref="randomize"
      class="userpic-top-icon-block opacity_0 hover_opacity-0_75"
      @click="randomizeUserPic()"
    >
      <div class="square-1_5 icon-grey-second icon-cached" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { logAnalyticsEvent } from '@/firebase/analytics'

export default {
  name: 'UserPicComponent',
  props: {
    showEdit: {
      type: Boolean,
      default: false
    },
    randomize: {
      type: Boolean,
      default: false
    },
    preview: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters([
      'userPic'
    ])
  },
  methods: {
    userPicError() {
      this.$store.commit('setUserPic', null)
    },
    randomizeUserPic () {
      if (this.$refs.randomize && this.$refs.randomize.blur) { this.$refs.randomize.blur()  }

      const symbols = '0123456789ABCDEF'

      let randomColor = ''
      for (let i = 0; i < 6; i++) {
        randomColor = randomColor + symbols[Math.floor((Math.random() * symbols.length))]
      }

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      ctx.canvas.width = 150
      ctx.canvas.height = 150

      ctx.fillStyle = '#' + randomColor
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      const resizedImageDataStr = canvas.toDataURL('image/png')

      this.$emit('set-random-user-pic-color', resizedImageDataStr)
      logAnalyticsEvent('randomizeUserPicClicked')
    }
  }
}
</script>