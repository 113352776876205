<template>
  <div>
    <div
      class="drawer"
      :class="{
        'drawer-shown': menuIsOpen,
        'drawer-closed': !menuIsOpen
      }"
    >
      <div>
        <div class="drawer-user-section">
          <div class="drawer-user-section-top">
            <UserPic
              :show-edit="true"
              @clicked="showEditUserPic()"
            />

            <div class="drawer-user-section-username-block">
              <div 
                v-if="showEditName"
                v-click-outside="closeEditName"
                class="framed-input-box drawer-edit-username-block-color"
              >
                <input
                  ref="name"
                  v-model.trim="newName"
                  type="text"
                  :maxlength="checkValues('userName', 'max')"
                  :placeholder="$filters.localizeFilter('User')"
                  class="drawer-edit-username-input"
                  @keydown.esc.prevent.stop="closeEditName()"
                  @keydown.enter.prevent.stop="saveName()"
                >

                <DoneGreenButton
                  v-if="newName !== userName"
                  @clicked="saveName()"
                />
              </div>

              <div
                v-else
                class="drawer-username-block cursor-pointer"
                @click.prevent.stop="showEditNameFunc()"
              >
                <div
                  v-if="!userName"
                  class="overflow-dots"
                >
                  <span class="link-dotted-bottom">{{ $filters.localizeFilter('User') }}</span>
                </div>

                <div
                  v-else
                  v-tooltip.bottom="{
                    text: userName,
                    padding: 6,
                    startAfter: 300
                  }"
                  class="drawer-username"
                >
                  <div
                    v-for="(part, idx) of userName.split(' ')"
                    :key="part + idx + ''"
                    class="overflow-dots"
                  >
                    <span class="link-dotted-bottom">{{ part }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="drawer-user-section-email">
            <div
              v-tooltip.bottom="{
                text: userEmail,
                padding: 6,
                startAfter: 300
              }"
              class="overflow-dots drawer-email"
            >
              {{ userEmail }}
            </div>
          </div>
        </div>

        <div class="drawer-links-section">
          <div v-if="online">
            <router-link
              v-for="link in links"
              :key="link.name"
              class="drawer-link"
              :class="{
                'cursor-default': $route.name === link.name,
                'animation-pulse': link.name === lessonStepPage && menuIsOpen
              }"
              :active-class="!lessonStepPage ? 'opacity-0_5' : null"
              :to="{ name: link.name }"
              :exact="link.exact"
            >
              <span @click="close()">{{ $filters.localizeFilter(link.title) }}</span>

              <div class="drawer-news-badge">
                <NewsBadge
                  v-if="link.name === 'News' && newsArr && newsArr.length && +news[newsArr[0]].timestamp > +userStats('newsRead')"
                  :key="newsArr.length"
                  :text="newsArr.length < 10000 ? newsArr.length + '' : '!'"
                />
              </div>
            </router-link>
          </div>

          <div
            class="drawer-link"
            @click.prevent="logout()"
          >
            {{ $filters.localizeFilter('Logout') }}
          </div>
        </div>
      </div>

      <div class="drawer-bottom-section">
        <div class="drawer-social-icons">
          <a
            v-for="socialLink of socialLinks"
            :key="socialLink.title"
            :id="'soc-net-img-' + socialLink.title"
            :href="socialLink.url"
            target="_blank"
            :title="socialLink.title"
            class="drawer-solial-link"
            @click="socNetClicked(socialLink.title)"
          >
            <img
              :src="getImgUrl(socialLink.logo)"
              loading="lazy"
              class="drawer-solial-link-icon"
              @error="imgError('soc-net-img-' + socialLink.title)"
            >
          </a>
        </div>

        <div class="drawer-bottom-section-block">
          <div
            class="extra-thin-text-button"
            @click.prevent="showFeedback()"
          >
            {{ $filters.localizeFilter('Feedback') }}
          </div>
        </div>

        <div class="drawer-bottom-section-block">
          <a
            :href="helpLink"
            target="_blank"
            class="drawer-bottom-section-block-text hover_opacity-0_75"
            @click="helpLinkClicked()"
          >
            {{ $filters.localizeFilter('Help') }}
          </a>
        </div>
        
        <div class="drawer-bottom-section-block">
          <span class="drawer-bottom-section-block-text">v{{ appVersion }}</span>

          <a
            :href="legalLink"
            target="_blank"
            class="drawer-bottom-section-block-text hover_opacity-0_75"
            @click="legalLinkClicked()"
          >
            {{ $filters.localizeFilter('LegalInformation') }}
          </a>
        </div>
      </div>
    </div>

    <div
      v-if="menuIsOpen"
      class="modal-background z_400"
      :class="{'modal-background-disappearing': closingDrawer}"
      @click="close()"
    />

    <div
      class="drawer-close-button icon-close"
      :class="{
        'drawer-close-button-opened': menuIsOpen && !closingDrawer,
        'drawer-close-button-closed': closingDrawer || !menuIsOpen,
      }"
      @click.stop="close()"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { getUserEmail } from '@/firebase/auth'
import UserPic from '@/components/profile/UserPic'
import { logAnalyticsEvent } from '@/firebase/analytics'
import checkInputValueMixin from '@/mixins/checkInputValue.mixin'

export default {
  name: 'DrawerComponent',
  mixins: [checkInputValueMixin],
  components: {
    UserPic,
    DoneGreenButton: defineAsyncComponent(() => import('@/components/UI/buttons/DoneGreenButton')),
    NewsBadge: defineAsyncComponent(() => import('@/components/app/badges/NewsBadge'))
  },
  data: () => ({
    waitigTime: 125,
    showEditName: false,
    newName: '',
    closingDrawer: false
  }),
  computed: {
    ...mapGetters([
      'appVersion',
      'helpLink',
      'legalLink',
      'lessonStep',
      'menuIsOpen',
      'news',
      'online',
      'processing',
      'remoteConfig_country',
      'userName',
      'userStats'
    ]),
    newsArr () {
      return this.$store.getters.newsArr(true)
    },
    userEmail () {
      return getUserEmail()
    },
    links() {
      const allLinks = [
        { title: 'Transactions', name: 'Transactions', exact: true },
        { title: 'Accounts', name: 'Accounts' },
        { title: 'Tags', name: 'Tags' },
        { title: 'News', name: 'News', exact: true, lessonStepHide: true },
        { title: 'Settings', name: 'Settings' }
      ]

      const arr = []

      for(const link of allLinks) {
        if (!this.lessonStep || !link.lessonStepHide) { arr.push(link) }
      }

      return arr
    },
    socialLinks () {
      const links = [
        { logo: 'fb.svg', url: 'https://www.facebook.com/BlueMoneyBoxApp', title: 'facebook', hide: (this.remoteConfig_country && ['RUS'].includes(this.remoteConfig_country)) },
        { logo: 'vk.svg', url: 'https://vk.com/bluemoneybox', title: 'vk', hide: (!navigator.languages.includes('ru-RU') && !navigator.languages.includes('ru')) },
        { logo: 'telegram.svg', url: 'https://t.me/bluemoneybox', title: 'telegram' },
        { logo: 'twitter.svg', url: 'https://twitter.com/bluemoneybox', title: 'twitter' },
        { logo: 'youtube.svg', url: 'https://www.youtube.com/channel/UCNL62wXGgfwk-y2XfkpInhA', title: 'youtube' },
        { logo: 'instagram.svg', url: 'https://instagram.com/bluemoneybox', title: 'instagram', hide: (this.remoteConfig_country && ['RUS'].includes(this.remoteConfig_country)) },
        { logo: 'tiktok.svg', url: 'https://www.tiktok.com/@bluemoneybox', title: 'tiktok' },
        { logo: 'snapchat.svg', url: 'https://www.snapchat.com/add/bluemoneyboxapp', title: 'snapchat' }
      ]

      let arr = []
      for (const link of links) {
        if (!link.hide) { arr.push(link) }
      }
      return arr
    },
    lessonStepPage () {
      if (this.lessonStep) {
        const steps = this.lessonStep.split('-')
        if (steps[1]) {
          return steps[1]
        } else {
          return null
        }
      }
      return null
    }
  },
  watch: {
    userName() {
      this.showEditName = false
    }
  },
  methods: {
    getImgUrl(filename) {
      return require('@/assets/img/social/' + filename)
    },
    imgError(id) {
      const img = document.getElementById(id)
      img.style.display = 'none'
    },
    async logout() {
      this.close(true)
    },
    showFeedback() {
      if (!this.online) { return }
      this.close()

      setTimeout(() => {
        this.$store.commit('setAppShowField', { feedback: true })
      }, this.waitigTime)
      
      logAnalyticsEvent('showFeedbackFromDrawerClicked')
    },
    showEditNameFunc() {
      if (this.processing) { return }
      this.newName = this.userName
      this.showEditName = true
      setTimeout(() => {
        if (this.$refs.name && this.$refs.name.focus) {
          this.$refs.name.focus()
        }
      }, 0)

      logAnalyticsEvent('showEditNameFuncClicked')
    },
    closeEditName() {
      this.showEditName = false
    },
    async saveName() {
      if (this.processing) { return }
      this.closeEditName()
      if (this.newName === this.userName) { return }
      this.$store.dispatch('saveUserName', this.newName)
    },
    showEditUserPic() {
      this.close()

      setTimeout(() => {
        this.$store.commit('setAppShowField', { editUserPic: true })
      }, this.waitigTime)

      logAnalyticsEvent('showEditUserPicClicked')
    },
    legalLinkClicked () {
      logAnalyticsEvent('legalLinkFromDrawerClicked')
    },
    helpLinkClicked () {
      logAnalyticsEvent('helpLinkFromDrawerClicked')
    },
    socNetClicked(title) {
      logAnalyticsEvent('socNetFromDrawerClicked', { social: title })
    },
    close(needLogout) {
      this.closingDrawer = true

      setTimeout(() => {
        this.$store.dispatch('setMenuIsOpenAction', false)
        this.closingDrawer = false

        if (needLogout) {
          this.$store.dispatch('logoutAndGoToLogin', { from: 'drawer-logout-button' })
        }
      }, this.waitigTime - 1)
    }
  }
}
</script>