<template>
  <div class="left-menu">
    <div
      class="menu-toggle-icon-block enter-from-top"
       @click="openMenu()"
    >
      <div
        class="menu-icon menu-icon-normal icon-menu square-2"
      />

      <div
        v-if="lessonStep === 'MenuToggle' && !menuIsOpen"
        class="menu-toggle-round"
      />

      <div
        v-if="!menuIsOpen && newsArr && newsArr.length"
        class="menu-toggle-badge"
      >
        <NewsBadge
          text="!"
        />
      </div>
    </div>

    <div class="enter-from-left">
      <div
        v-for="block of blocks"
        :key="block.id + '-' + lessonStep + '-' + block.iconActive"
        class="menu-icon-block cursor-pointer"
        :class="{
          'menu-icon-block-hidden': !block.show,
          'menu-icon-block-margin': block.show
        }"
        v-tooltip.right="{
          text: block.tooltipText ? block.tooltipText : null,
          padding: 6,
          shown: (block.id === 'AddButton' && route.name === 'Transactions' && lessonStep === 'MenuTransactions'),
          startAfter: 500
        }"
        @click.prevent="clickedButton(block.id)"
      >
        <div
          class="menu-icon"
          :class="`${block.show ? (block.iconLarge ? 'menu-icon-size-large' : 'menu-icon-size') : 'menu-icon-hidden'} ${block.icon} ${block.iconActive ? 'menu-icon-active' : 'menu-icon-normal'} ${block.opacity ? 'opacity-0_75' : ''}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { logAnalyticsEvent } from '@/firebase/analytics'

export default {
  name: 'LeftMenuComponent',
  components: {
    NewsBadge: defineAsyncComponent(() => import('@/components/app/badges/NewsBadge'))
  },
  computed: {
    ...mapGetters([
      'accounts',
      'accountsFiltered',
      'accountsFilters',
      'canSaveSpread',
      'canUseLimit',
      'cards',
      'cardsFiltered',
      'cardsFilters',
      'chosenSpreadId',
      'clickedTransactionId',
      'defaultAccount',
      'getLimitNumber',
      'lessonStep',
      'loadingPage',
      'menuIsOpen',
      'moneyBoxes',
      'moneyBoxesFiltered',
      'online',
      'showArchivedAccounts',
      'showArchivedCards',
      'showArchivedTags',
      'spreads',
      'tagsFiltered',
      'tagsCollectionsFiltered',
      'transactions',
      'transactionsFiltered',
      'transactionsSettingsFiltered'
    ]),
    newsArr () {
      return this.$store.getters.newsArr(true)
    },
    route () {
      return this.$route
    },
    blocks () {
      const arr = []

      const pages = {
        'Accounts': [
          { id: 'AddButton', show: true, icon: 'icon-add', iconLarge: true, handler: { dispatch: 'showCreateAccountClicked', values: { from: 'leftMenuButton' } }, tooltipText: this.$filters.localizeFilter('CreateAccount') + ' (Ctrl + B)' },
          { id: 'Star', show: !!(this.defaultAccount && this.defaultAccount.accountId && this.accounts[this.defaultAccount.accountId] && Object.keys(this.accounts).length && Object.keys(this.accounts).length > 1), icon: this.accountsFilters.onlyDefault ? 'icon-star' : 'icon-star_border', iconActive: this.accountsFilters.onlyDefault, iconLarge: true, handler: { dispatch: 'showStarAccountClicked', values: { show: !this.accountsFilters.onlyDefault, from: 'leftMenuButton'} }, tooltipText: this.accountsFilters.onlyDefault ? this.$filters.localizeFilter('ClearFilter') : this.$filters.localizeFilter('ShowOnlyMainAccount') },
          { id: 'FilterButton', show: !!(Object.keys(this.accounts).length && Object.keys(this.accounts).length > 1), icon: this.accountsFiltered ? 'icon-filter_alt' : 'icon-filter_alt_empty', iconActive: this.accountsFiltered, handler: { dispatch: 'showFilterAccountsButtonClicked', values: { section: null, value: true } }, tooltipText: this.$filters.localizeFilter('SetFilter') },
          { id: 'ClearAllFilters', show: this.accountsFiltered, icon: 'icon-filter_alt_off', iconActive: true, handler: { dispatch: 'clearAccountsAllFilters', values: {} }, tooltipText: this.$filters.localizeFilter('ClearFilters') },
          { id: 'Archive', show: true, icon: this.showArchivedAccounts ? 'icon-inventory_2_filled': 'icon-inventory', iconActive: this.showArchivedAccounts, handler: { dispatch: 'showArchivedAccountsClicked', values: {} }, tooltipText: this.showArchivedAccounts ? (this.$filters.localizeFilter('HideArchivedAccounts') + ' (Ctrl + A)') : (this.$filters.localizeFilter('ShowArchivedAccounts') + ' (Ctrl + A)') },
          { id: 'Banks', show: true, icon: 'icon-account_balance', opacity: !this.getLimitNumber('maxBanks'), handler: { dispatch: 'openBanksClicked', values: {} }, tooltipText: this.$filters.localizeFilter('Banks') },
          { id: 'Cards', show: true, icon: 'icon-credit_card', opacity: !this.getLimitNumber('maxCards'), handler: { dispatch: 'openCardsClicked', values: {} }, tooltipText: this.$filters.localizeFilter('Cards') },
          { id: 'MoneyBoxesButton', show: true, icon: 'icon-savings', opacity: !this.getLimitNumber('maxMoneyBoxes'), handler: { dispatch: 'openMoneyBoxesClicked', values: {} }, tooltipText: this.$filters.localizeFilter('MoneyBoxes') },
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'MoneyBoxes': [
          { id: 'AddButton', show: true, icon: 'icon-add', iconLarge: true, handler: { dispatch: 'addMoneyBoxButtonClicked', values: { from: 'leftMenuButton' } }, tooltipText: this.$filters.localizeFilter('AddMoneyBox') + ' (Ctrl + B)' },
          { id: 'FilterButton', show: !!(Object.keys(this.moneyBoxes).length && Object.keys(this.moneyBoxes).length > 1), icon: this.moneyBoxesFiltered ? 'icon-filter_alt': 'icon-filter_alt_empty', iconActive: this.moneyBoxesFiltered, handler: { dispatch: 'showFilterMoneyBoxesButtonClicked', values: { section: null, value: true } }, tooltipText: this.$filters.localizeFilter('SetFilter') },
          { id: 'ClearAllFilters', show: this.moneyBoxesFiltered, icon: 'icon-filter_alt_off', iconActive: true, handler: { dispatch: 'clearMoneyBoxesFiltersClicked', values: {} }, tooltipText: this.$filters.localizeFilter('ClearFilters') },
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'Banks': [
          { id: 'AddButton', show: true, icon: 'icon-add', iconLarge: true, handler: { dispatch: 'showCreateBankClicked', values: { from: 'leftMenuButton' } }, tooltipText: this.$filters.localizeFilter('CreateBank') + ' (Ctrl + B)' },
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'Cards': [
          { id: 'AddButton', show: true, icon: 'icon-add', iconLarge: true, handler: { dispatch: 'showCreateCardClicked', values: { from: 'leftMenuButton' } }, tooltipText: this.$filters.localizeFilter('CreateCard') + ' (Ctrl + B)' },
          { id: 'Star', show: !!(this.defaultAccount && this.defaultAccount.cardId && this.cards[this.defaultAccount.cardId] && Object.keys(this.cards).length && Object.keys(this.cards).length > 1), icon: this.cardsFilters.onlyDefault ? 'icon-star' : 'icon-star_border', iconActive: this.cardsFilters.onlyDefault, iconLarge: true, handler: { dispatch: 'showStarCardClicked', values: { show: !this.cardsFilters.onlyDefault, from: 'leftMenuButton'} }, tooltipText: this.cardsFilters.onlyDefault ? this.$filters.localizeFilter('ClearFilter') : this.$filters.localizeFilter('ShowOnlyMainAccount') },
          { id: 'FilterButton', show: !!(Object.keys(this.cards).length && Object.keys(this.cards).length > 1), icon: this.cardsFiltered ? 'icon-filter_alt' : 'icon-filter_alt_empty', iconActive: this.cardsFiltered, handler: { dispatch: 'showFilterCardsButtonClicked', values: { section: null, value: true } }, tooltipText: this.$filters.localizeFilter('SetFilter') },
          { id: 'ClearAllFilters', show: this.cardsFiltered, icon: 'icon-filter_alt_off', iconActive: true, handler: { dispatch: 'clearCardsAllFilters', values: {} }, tooltipText: this.$filters.localizeFilter('ClearFilters') },
          { id: 'Archive', show: true, icon: this.showArchivedCards ? 'icon-inventory_2_filled': 'icon-inventory', iconActive: this.showArchivedCards, handler: { dispatch: 'showArchivedCardsClicked', values: {} }, tooltipText: this.showArchivedCards ? (this.$filters.localizeFilter('HideArchivedCards') + ' (Ctrl + A)') : (this.$filters.localizeFilter('ShowArchivedCards') + ' (Ctrl + A)') },
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'PutToAccounts': [
          { id: 'AddToAccountsButton', show: (this.spreads && Object.keys(this.spreads).length), icon: 'icon-view_agenda', handler: { dispatch: 'showSavedSpreadsClicked', values: {} }, tooltipText: this.$filters.localizeFilter('SavedAccountDistributions') },
          { id: 'Save', show: this.canSaveSpread, icon: 'icon-save', handler: { dispatch: 'showSaveSpreadsClicked', values: { button: this.chosenSpreadId  ? 'edit' : 'add', from: 'leftMenuButton', spreadId: this.chosenSpreadId } }, opacity: (!this.canUseLimit('spreads', false) || (this.spreads && Object.keys(this.spreads).length >= 5)), tooltipText: this.$filters.localizeFilter('SaveDraftAccountDistribution') },
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'Settings': [
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'Tags': [
          { id: 'AddButton', show: true, icon: 'icon-add', iconLarge: true, handler: { dispatch: 'setTagsShow', values: { tagId: null, button: 'add', from: 'leftMenuButton' } }, tooltipText: this.$filters.localizeFilter('AddTag') + ' (Ctrl + B)' },
          { id: 'ClearAllFilters', show: this.tagsFiltered, icon: 'icon-filter_alt_off', iconActive: true, handler: { dispatch: 'clearTagsFiltersClicked', values: { from: 'leftMenuButton' } }, tooltipText: this.$filters.localizeFilter('ClearFilters') },
          { id: 'Archive', show: true, icon: this.showArchivedTags ? 'icon-inventory_2_filled' : 'icon-inventory', iconActive: this.showArchivedTags, handler: { dispatch: 'showArchivedTagsClicked', values: {} }, tooltipText:  this.showArchivedTags ? this.$filters.localizeFilter('HideArchivedTags') : (this.$filters.localizeFilter('ShowArchivedTags')) },
          { id: 'TagsCollections', show: true, icon: 'icon-grid_view', iconActive: false, handler: { func: this.goToTagsCollections, values: {} }, tooltipText:  this.$filters.localizeFilter('TagsCollections') },
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'TagsCollections': [
          { id: 'AddButton', show: true, icon: 'icon-add', iconLarge: true, handler: { dispatch: 'setTagsCollectionsShow', values: { tagsCollectionId: null, from: 'leftMenuButton', button: 'add' } }, tooltipText: this.$filters.localizeFilter('CreateCollection') + ' (Ctrl + B)' },
          { id: 'ClearAllFilters', show: this.tagsCollectionsFiltered, icon: 'icon-filter_alt_off', iconActive: true, handler: { dispatch: 'clearTagsCollectionsFiltersClicked', values: { from: 'leftMenuButton' } }, tooltipText: this.$filters.localizeFilter('ClearFilters') },
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'TagStatistics': [
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ],
        'Transactions': [
          { id: 'AddButton', show: true, icon: 'icon-add', iconLarge: true, handler: { dispatch: 'addTransactionButtonClicked', values: { from: 'leftMenuButton'} }, tooltipText: this.$filters.localizeFilter('AddTransaction') + (this.lessonStep === 'MenuTransactions' ? '' : ' (Ctrl + K)') },
          { id: 'CopyButton', show: !!(this.clickedTransactionId), icon: 'icon-library_add', handler: { dispatch: 'copyTransactionClicked', values: { from: 'leftMenuButton'} }, tooltipText: this.$filters.localizeFilter('CopyTransaction') + ' (Ctrl + Shift + C)' },
          { id: 'DeleteButton', show: !!(this.clickedTransactionId), icon: 'icon-delete', handler: { dispatch: 'deleteTransactionClicked', values: {} }, tooltipText: this.$filters.localizeFilter('DeleteTransaction') + ' (Ctrl + Del)' },
          { id: 'AddToAccountsButton', show: true, icon: 'icon-view_agenda', opacity: !this.canUseLimit('spreads', false), handler: { dispatch: 'openPutToAccounts', values: {} }, tooltipText: this.$filters.localizeFilter('AddToAccounts') },
          { id: 'FilterButton', show: (Object.keys(this.transactions).length > 1), icon: (this.transactionsFiltered ? 'icon-filter_alt' : 'icon-filter_alt_empty'), iconActive: this.transactionsFiltered, handler: { dispatch: 'showFilterTransactionsButtonClicked', values: { section: null, value: true } }, tooltipText: this.$filters.localizeFilter('SetFilter') },
          { id: 'ClearAllFilters', show: this.transactionsFiltered, icon: 'icon-filter_alt_off', iconActive: true, handler: { dispatch: 'clearTransactionsFilters', values: {} }, tooltipText: this.$filters.localizeFilter('ClearFilters') },
          { id: 'Settings', show: true, icon: this.transactionsSettingsFiltered ? 'icon-settings_filled' : 'icon-settings_outlined', iconActive: this.transactionsSettingsFiltered, opacity: !this.canUseLimit('setTransactionsSettings', false), handler: { dispatch: 'showTransactionsSettingsClicked', values: {} }, tooltipText: this.$filters.localizeFilter('SetLoadingOptions') },
          { id: 'Help', show: true, icon: 'icon-help', handler: { func: this.openHelp }, tooltipText: this.$filters.localizeFilter('Help')}
        ]
      }

      if (!this.route || !this.route.name || !this.online || this.loadingPage || !pages[this.route.name]) { return arr }

      const buttons = pages[this.route.name]

      for (const button of buttons) {
        if (button.icon) {
          arr.push(button)
        }
      }

      return arr
    }
  },
  methods: {
    openMenu () {
      this.$store.dispatch('setMenuIsOpenAction', !this.menuIsOpen)
      logAnalyticsEvent('menuToggleClicked')
    },
    openHelp() {
      let link = this.$store.getters.helpLink

      if (this.route.name) {
        let helpsection = this.route.name
        let location

        if (this.route.name === 'MoneyBoxes') {
          helpsection = "Accounts"
          location = "MoneyBoxes"
        } else if (this.route.name === 'PutToAccounts') {
          helpsection = "Transactions"
          location = "DistributionsAmongAccounts"
        } else if (this.route.name === 'Banks') {
          helpsection = "Accounts"
          location = "Banks"
        } else if (this.route.name === 'Cards') {
          helpsection = "Accounts"
          location = "Cards"
        } else if (this.route.name === 'TagsCollections') {
          helpsection = "Tags"
          location = "TagsCollections"
        } else if (this.route.name === 'TagStatistics') {
          helpsection = "Tags"
          location = "TagStatistics"
        }

        link = link + '&helpsection=' + helpsection

        if (location) {
          link = link + '&location=' + location
        }
      }

      window.open(link, '_blank')

      if (!location) { location = null }
      logAnalyticsEvent('openHelpFromLeftMenuClicked', { page: this.route.name, location: location })
    },
    goToTagsCollections() {
      this.$router.push({ name: 'TagsCollections' }).catch(() => { })
      logAnalyticsEvent('goToTagsCollectionsMenuClicked')
    },
    clickedButton(blockId) {
      if (!blockId) { return }

      const index = this.blocks.findIndex(element => {
        return element.id === blockId
      })
      if (index < 0) { return }

      const block = this.blocks[index]
      if (!block.show) { return }

      if (block.handler) {
        if (block.handler.dispatch) {
          this.$store.dispatch(block.handler.dispatch, block.handler.values)
        }

        if (block.handler.func) {
          block.handler.func()
        }
      }
    }
  }
}
</script>